*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-family: 'Roboto';
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.overlay-controls {
    width: 100vw;
    height: 60px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 24px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
}

#loading-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 20;
}

#loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 48px;
    padding: 30px 40px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

#start {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 48px;
    justify-content: center;
}

#start:hover {
    cursor: pointer;
    color: #06072d;
}

.back {
    display: none;
    align-items: center;
    margin-left: 20px;
    color: white;
    font-weight: bold;
    font-size: 24px;
}

.center-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    visibility: hidden;
    text-align: center;
}

.fill-container {
    visibility: hidden;
}

.section-header {
    display: none;
    margin: 0px 40px;
}


button {
    border: none;
    background: transparent;
    outline: none;
}

button:hover {
    cursor: pointer;
}

#instructions-overlay {
    font-family:'Times New Roman', Times, serif;
    width: 300px;
    height: 40px;
    position: fixed;
    top: 100px;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #e4e4e4;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s;
}

#back-icon {
    display: block;
}

#back-text {
    display: none;
}

@media only screen and (max-width: 600px) {
    .center-controls {
        font-size: 16px;
    }

    .back {
        font-size: 16px;
    }

    .section-header {
        margin: 0px 10px;
    }
}